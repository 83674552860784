<template>
  <NuxtLink :to="localePath(infoBox.link.href)" :class="['info-box']" :aria-label="infoBox.title">
    <div
      class="inner-container flex category-block"
      @mouseenter="setShowHoverImage(true)"
      @mouseleave="setShowHoverImage(false)"
    >
      <picture class="flex">
        <source :srcset="getSrcset(mobileImage, state.mobSize)" media="(max-width: 767px)" height="360" width="360" />
        <source :srcset="getSrcset(img, state.defaultSize)" media="(min-width: 768px)" height="200" width="200" />
        <img
          :srcset="getSrcset(mobileImage, state.mobSize)"
          class="image"
          height="200"
          width="200"
          :alt="infoBox.title"
        />
      </picture>
      <div class="flex-col content category-card">
        <h3 v-if="infoBox.title" class="card-title">{{ infoBox.title }}</h3>
        <CustomButton v-if="infoBox.button_text" :is-small="true" :href="infoBox.link.href || '#'" style-color="white">
          {{ infoBox.button_text }}
        </CustomButton>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { useCloudinary } from '~/composables/cloudinary';
import { AssetCloudinary, InfoBoxData } from '~/types/contentstack';
import { getAssetPublicId } from '~/util/contentstack/csHelpers';

const cloudinary = useCloudinary();

const props = defineProps({
  /* eslint-disable vue/prop-name-casing */
  show_image_overlay: {
    type: Boolean,
    default: true,
  },
  /* eslint-enable vue/prop-name-casing */
  infoBox: {
    type: Object as () => InfoBoxData,
    required: true,
  },
});

const localePath = useLocalePath();

const state = reactive({
  defaultSize: 200,
  mobSize: 360,
  showHoverImage: false,
});

const mobileImg = computed(() => props.infoBox?.mobile_image);
const img = computed(() => props.infoBox?.image);
const hoverImg = computed(() => props.infoBox?.image[1]);
const mobileImage = computed(() => {
  return mobileImg.value?.length ? mobileImg.value : img.value;
});

function setShowHoverImage(showHoverImage: boolean) {
  state.showHoverImage = showHoverImage;
}

function resize(img: AssetCloudinary[], width: number, height: number): string {
  const hasHover = typeof hoverImg?.value?.public_id !== 'undefined';
  const publicId = state.showHoverImage && hasHover ? hoverImg?.value?.public_id : (getAssetPublicId(img) as string);
  return cloudinary.bynderToCloudinaryResize(publicId, width, height);
}
function getSrcset(url: any, size: number) {
  return `${resize(url, size, size)} 1x, ${resize(url, size * 1.5, size * 1.5)} 2x, ${resize(
    url,
    size * 2,
    size * 2
  )} 3x`;
}
</script>

<style lang="scss" scoped>
.info-box {
  background: #f4f4f4;
  border-radius: 4px;
  .inner-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem 1rem;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    picture {
      margin-bottom: 0.5rem;
      @include local-mixins.desktop_and_tablet {
        margin-bottom: 1.25rem;
      }
    }
    img {
      border-radius: 4px;
      width: 100%;
      height: auto;
    }
    @include local-mixins.mobile {
      padding: 0.25rem 0.5rem;
    }
    @include local-mixins.desktop_and_tablet {
      // height: 260px;
      width: 100%;
      // .content {
      //   // z-index: 1;
      // }
    }
    @include local-mixins.tablet {
      img {
        // margin: 4rem 2.5rem 1rem 0.5rem;
      }
    }
  }

  .card-title {
    color: $color-neutral-black;
    font-size: 1.125rem;
    text-align: center;
    + .btn {
      margin-top: 1.5rem;
    }
  }
  .card-desc {
    color: $color-neutral-black;
    font-size: 0.75rem;
  }
  img {
    transition: transform 0.2s ease;
  }

  @include local-mixins.desktop {
    &:hover {
      img {
        transform: translateY(-6px);
      }
    }
  }

  @include local-mixins.mobile {
    // width: 100%;
    // min-width: unset;
    // height: 150px;
    .card-title {
      font-size: 0.875rem;
      // margin-left: 0.25rem;
      font-family: local-vars.$ff-text;
      font-weight: 500;
    }
    img {
      // border-radius: 4px;
      // height: 100px;
      // width: 100px;
      // position: relative;
    }
    .card-desc {
      display: none;
    }
    .category-block {
      display: block;
    }
  }
}
// .category-card {
//   flex-direction: column-reverse;
//   width: 100%;
//   text-align: center;
// }
// @include local-mixins.mobile {
//   .category-card {
//     padding: 10px 0 0 0;
//   }
// }
</style>
